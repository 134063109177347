import {HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {from} from 'rxjs';
import {InterceptorService} from '../../../core/services/helpers/interceptor.service';

@Injectable()
export class Interceptor implements HttpInterceptor {

    constructor(private interceptorService: InterceptorService) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler) {
        return from(this.acquireToken(req, next));
    }

    private async acquireToken(req: HttpRequest<any>, next: HttpHandler) {
        const token = await this.interceptorService.acquireToken();
        const headers = req.clone({
            headers: req.headers.set('Authorization', `Bearer ${token}`)
        });
        return next.handle(headers).toPromise();
    }
}
