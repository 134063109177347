import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-viewnews',
  templateUrl: './viewnews.component.html',
  styleUrls: ['./viewnews.component.css']
})
export class ViewnewsComponent implements OnInit {

  data: any; 

  constructor(
    @Inject(MAT_DIALOG_DATA) private entrar: any,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.data = this.entrar.view;
  }

  viewNew(id: string) {
    this.router.navigateByUrl("/noticias/" + id);
  }
}
