
<button mat-icon-button class="close-button" [mat-dialog-close]="true">
    <mat-icon class="close-icon">close</mat-icon>
</button>
<div class="modal-container">
    <div><bc-icon class="img bc-3xl bc-icon">lightbulb</bc-icon></div>
    <div class="title_result">{{data.title}}</div>
    <div *ngIf="data.imageKey"><img class="img-view" alt="default-data" [src]="data.imageUrl"></div>
    <div class="modal-title">{{data.body}}</div>
</div>
<div class="modal-button">
    <button bc-button sizeButton="small" [mat-dialog-close]="true" (click)="viewNew(data.id)">Ver Noticia</button>
</div>
