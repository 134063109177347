import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";

import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { endpoint } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class NewsService {
  news = {};
  endpoint = endpoint.endpointParametrizationProd;
  endpointProd = endpoint.endpointParametrizationProd;
  private newtoedit = new BehaviorSubject(null);
  currentnew = this.newtoedit.asObservable();
  constructor(public http: HttpClient) { }

/**
 * metodo que retorna todas las noticias desde el back
 */
  getNews() {
    return new Promise(resolve => {
      this.http.get(`${this.endpoint.toLowerCase()}news`).subscribe(data => {
        resolve(data)
      })
    })
  }

  changeNews(currentnew: any[]) {
    this.newtoedit.next(currentnew)
  }
  deleteNews(news: any[]) {
    const array = [];
    news.forEach(element => {
      new Promise(resolve => {
        this.http.delete(`${this.endpoint.toLowerCase()}news/${element.id}`).subscribe(data => {
          resolve(data)
        })
      }).then((data: any) => {
        if (data.status != 'success') {
          array.push(element.id)
        }
      })
    });
    return array;
  }

  deleteNew(id: any) {
    return new Promise(resolve => {
      this.http.delete(`${this.endpoint.toLocaleLowerCase()}news/${id}`).subscribe((data: any) => {
        if (data.status === 'success') {
          resolve(data);
        } else {
          throw new Error(data.message);
        }
      });
    });
  }

  createNew(obj: any, isDuplicate: boolean) {
    let newFile;
    if(obj.image) {
      const file = obj.image;
      const name = file.name.replace(/[^A-Za-z0-9\s.]/g, "");
      const blob = file.slice(0, file.size,`${file.type}`);
      newFile = new File(  [blob], `${name}`, { type: `${file.type}` });
    } else {
      newFile = obj.image;
    }
    const formdata: FormData = new FormData();
    formdata.append('body', obj.body);
    formdata.append('title', obj.title);
    formdata.append('image', newFile);
    if (isDuplicate) {
      formdata.append('imageKey', obj.imageKey);
    }
    return new Promise(resolve => {
      this.http.post(`${this.endpoint.toLowerCase()}news`, formdata).subscribe((data: any) => {
        resolve(data);
      });
    });
  }

  updateNew(obj: any,id) {
    const file = obj.image;
    let newFile;
    if(file.name){
      const name = file.name.replace(/[^A-Za-z0-9\s.]/g, "");
      const blob = file.slice(0, file.size, `${file.type}`);
      newFile = new File(  [blob], `${name}`, { type: `${file.type}` });
    } else {
      newFile = obj.image;
    }
    const formdata: FormData = new FormData();
    formdata.append('image', newFile);
    formdata.append('body', obj.body);
    formdata.append('title', obj.title);
    return new Promise(resolve => {
      this.http.put(`${this.endpoint.toLowerCase()}news/${id}`, formdata).subscribe(data => {
        resolve(data)
      })
    })
  }

  getAllNewsFake(): Observable<any>{
    return this.http.get(this.endpointProd + "news").pipe(tap(
      (res : any) => {
      }
    ))
  }

  getNewFake(idNew:number): Observable<any>{
    return this.http.get(this.endpointProd + "news/"+ idNew).pipe(tap(
      (res : any) => {
      }
    ))
  }
}
